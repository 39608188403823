import React, { useState } from 'react';
import axios from 'axios';
import './login.css';

function Login({ setAuth }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://localhost:8001/authenticate', { username, password });
      if (response.data.authenticated) {
        setAuth(true);  
      } else {
        alert('Authentication failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Authentication error');
    }
  };

  return (
    <div className='section'>
      {Array.from({ length: 140 }, (_, index) => (
        <span key={index}></span>
      ))}
      <div className="signin">
        <div className="content">
          <h2>Sign In</h2>
          <form onSubmit={handleLogin} className="form">
            <div className="inputBox">
              <input type="text" required onChange={e => setUsername(e.target.value)} />
              <i>Username</i>
            </div>
            <div className="inputBox">
              <input type="password" required onChange={e => setPassword(e.target.value)} />
              <i>Password</i>
            </div>
            <div className="inputBox">
              <input type="submit" value="Login" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
