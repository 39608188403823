import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from './Loader'; 

const Home = () => {
  const [allCosts, setAllCosts] = useState({});
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchCosts = async () => {
      try {
        const response = await axios.get('http://localhost:8001/get_all_usage_data/');
        setAllCosts(response.data);
      } catch (error) {
        console.error("Error loading usage data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchCosts();
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center py-12">
      <div className="w-full max-w-3xl px-6 py-8 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold mb-8 text-center text-purple-500">
          Usage Costs Overview
        </h2>
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader /> 
          </div>
        ) : (
          Object.keys(allCosts).length > 0 ? (
            Object.keys(allCosts).map(monthYear => (
              <div key={monthYear} className="mb-6">
                <h3 className="text-2xl font-bold mb-4 text-yellow-400">
                  {`Month: ${monthYear}`}
                </h3>
                <div className="space-y-2">
                  <p className="flex justify-between">
                    <span><strong>Generation API:</strong></span>
                    <span>{allCosts[monthYear].Generation_API} x $0.05</span>
                  </p>
                  <p className="flex justify-between">
                    <span><strong>Pool Results:</strong></span>
                    <span>{allCosts[monthYear].Pool_Results} x $0.00003</span>
                  </p>
                  <p className="flex justify-between">
                    <span><strong>Requests:</strong></span>
                    <span>{allCosts[monthYear].Requests} x $0.00003</span>
                  </p>
                  <p className="mt-4 flex justify-between text-xl font-semibold text-green-400">
                    <span><strong>Total Cost:</strong></span>
                    <span>${allCosts[monthYear].Total_Cost.toFixed(4)}</span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-400">No data available.</p>
          )
        )}
      </div>
    </div>
  );
};

export default Home;
