import React, { useState } from "react";
import axios from "axios";
import Loader from './Loader';

function Create() {
  const [prompt, setPrompt] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusText, setStatusText] = useState("WAITING");
  const [error, setError] = useState('');
  const [jobId, setJobId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [user, setUser] = useState("deiu");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!prompt.trim()) { 
      setError('Please provide a prompt.'); 
      setLoading(false); 
      return; 
    }
    setError(''); 
    setLoading(true);
    setStatusText("WAITING");
  
    try {
      const response = await axios.post('http://localhost:8001/imagine/', { prompt });
      if (response.data && response.data.job_id) {
        setJobId(response.data.job_id);
        pollJobStatus(response.data.job_id, prompt);
      } else {
        throw new Error('No job ID returned from the server.');
      }
    } catch (error) {
      console.error('Error initiating image generation:', error);
      setError(error.response?.data?.detail || 'Failed to initiate image generation. Please try again.');
      setLoading(false);
    }
  };
  
  

  const executeOption = async (optionIndex, jobId) => {
    setLoading(true); 
    const image = images.find(img => img.jobId === jobId);
    const optionName = image.actions[optionIndex];
    const newPrompt = `${image.prompt} + ${optionName}`;
  
    try {
      const response = await axios.post('http://localhost:8001/execute_option/', {
        option: optionIndex,
        parent_id: jobId,
        prompt: newPrompt
      });
      if (response.data && response.data.id) {
        console.log('New job started:', response.data);
        pollJobStatus(response.data.id, newPrompt);
      } else {
        console.log('Failed to start new job:', response.data);
        setLoading(false); 
      }
    } catch (error) {
      console.error('Error executing option:', error);
      setError('Failed to execute option. Please try again.');
      setLoading(false);  
    }
  };
  

const pollJobStatus = (jobId, currentPrompt) => {
  const intervalId = setInterval(async () => {
    try {
      const response = await axios.get(`http://localhost:8001/job_status/${jobId}`);
      if (response.data) {
        console.log('Job status:', response.data);
        const jobStatus = response.data.status;
        setStatusText(jobStatus);
        setProgress(response.data.progress);

        setLoading(jobStatus === 'PREPARING' || jobStatus === 'WAITING');

        if (jobStatus === 'IN_PROGRESS' || jobStatus === 'SUCCEEDED' || jobStatus === 'FAILED') {
          setImages(prevImages => {
            const updatedImages = prevImages.filter(img => img.jobId !== jobId);
            return [{
              url: response.data.result,  
              actions: response.data.options || [],
              progress: response.data.progress,
              jobId,
              user,
              date: new Date().toLocaleString(),
              prompt: currentPrompt 
            }, ...updatedImages];
          });
        }

        if (jobStatus === 'SUCCEEDED' || jobStatus === 'FAILED') {
            setPrompt("");
            clearInterval(intervalId);
            setLoading(false);
            setTimeout(() => {
                setProgress(0);
            }, 5000);
        }
      }
    } catch (error) {
      console.error('Error fetching job status:', error);
      setError('Failed to fetch job status. Please try again.');
      clearInterval(intervalId);
      setLoading(false);
    }
  }, 20000);
};

  const Accordion = ({ user, date, prompt }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="mb-4">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full text-left bg-gray-800 text-white p-4 rounded-lg shadow-md focus:outline-none"
        >
          <div className="flex justify-between">
            <span><strong>User:</strong> {user}</span>
            <span><strong>Date:</strong> {date}</span>
          </div>
        </button>
        {isOpen && (
          <div className="bg-gray-700 text-white p-4 mt-2 rounded-lg">
            <p><strong>Prompt:</strong> {prompt}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen bg-gray-900 text-white flex flex-col items-center relative">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline">{error}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError('')}>
          <button
            aria-label="Închide mesajul de eroare"
            onClick={() => setError('')}
            className="absolute bottom-4 left-3 py-3"
          >
            <svg className="fill-current h-5 w-5 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.846 7.349 14.849a1.2 1.2 0 1 1-1.697-1.697L8.303 10 5.652 7.349A1.2 1.2 0 1 1 7.349 5.652L10 8.303l2.651-2.651a1.2 1.2 0 1 1 1.697 1.697L11.697 10l2.651 2.651a1.2 1.2 0 0 1 0 1.697z"/>
            </svg>
          </button>
          </span>
        </div>
      )}
      <div className="w-full max-w-2xl px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-purple-400 text-center">
          Generate Image
        </h1>
        <form onSubmit={handleSubmit} className="w-full mb-8 p-6 bg-gray-800 shadow-md rounded-lg">
          <div className="mb-4">
            <label className="block text-gray-300 text-lg font-semibold mb-2">
              Prompt:
            </label>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="border border-gray-700 rounded-lg w-full h-40 px-3 py-2 text-white bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400 resize-y"
              rows="4"
            />
          </div>
          <div className="mb-4 flex items-center space-x-4">
            <button
              type="submit"
              className="bg-purple-600 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
            >
              Generate
            </button>
          </div>
        </form>

        {loading && <Loader />}

        {(loading || progress > 0) && (
          <div className="mb-4 mt-5">
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
              <div className="bg-purple-600 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full" style={{ width: `${progress > 0 ? progress : 100}%` }}>
                {statusText || `${progress}%`}
              </div>
            </div>
          </div>
        )}

        {images.length > 0 && (
          <>
            <h2 className="text-xl font-bold mb-4 text-purple-400">Generated Images:</h2>
            <div className="w-full">
              {images.map((image, index) => (
                <div key={index} className="text-center mb-4">
                  <Accordion user={image.user} date={image.date} prompt={image.prompt} />
                  <img
                    src={image.url}
                    alt={`Generated ${image.progress}%`}
                    className="w-full rounded-lg mb-4"
                  />
                  <div className="flex flex-wrap justify-center items-center gap-2 w-full">
                      {image.actions.map((action, idx) => (
                          <button
                              key={idx}
                              onClick={() => executeOption(idx, image.jobId)}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline"
                              style={{ maxWidth: 'calc(100% - 10px)' }} 
                          >
                              {action}
                          </button>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Create;
