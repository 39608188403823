import React from 'react';
import { FaInstagram, FaLinkedin, FaTelegramPlane } from 'react-icons/fa';

function Contact() {
  return (
    <div className="w-full min-h-screen bg-gray-900 text-white p-4 flex justify-center items-center">
      <div className="text-center bg-gray-800 p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6 text-purple-400">Contact</h1>
        <div className="grid grid-cols-3 gap-4 justify-center items-center">
          <a href="https://www.instagram.com/andrei.designn/" target="_blank" rel="noreferrer"
             className="p-4 rounded-full hover:bg-purple-600 transition duration-300 ease-in-out flex justify-center items-center">
            <FaInstagram size="3em" />
          </a>
          <a href="https://www.linkedin.com/in/andrei-badulescu-55b993240" target="_blank" rel="noreferrer"
             className="p-4 rounded-full hover:bg-purple-600 transition duration-300 ease-in-out flex justify-center items-center">
            <FaLinkedin size="3em" />
          </a>
          <a href="https://t.me/andreiDesignn" target="_blank" rel="noreferrer"
             className="p-4 rounded-full hover:bg-purple-600 transition duration-300 ease-in-out flex justify-center items-center">
            <FaTelegramPlane size="3em" />
          </a>
        </div>
        <h4 className="text-lg font-semibold mt-8 text-purple-300">
          <a href="http://andrei-design.ro" className="hover:text-purple-200" target='_blank' rel="noreferrer">andrei-design</a>
        </h4>
      </div>
    </div>
  );
}

export default Contact;
