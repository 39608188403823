import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Create from './components/Create';
import Nav from './components/Nav';
import Home from './components/Home';
import { Blend } from './components/Blend';
import Contact from './components/Contact';
import Login from './components/Login';

function App() {
  const [isAuthenticated, setAuth] = useState(false);

  if (!isAuthenticated) {
    return <Login setAuth={setAuth} />;
  }

  return (
    <Router>
      <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create" element={<Create />} />
          <Route path="/blend" element={<Blend />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
    </Router>
  );
}

export default App;
